import gql from 'graphql-tag';

export const isAppLockedQuery = gql`
  query IsAppLocked {
    isAppLocked {
      locked
      nodes {
        id
        taskId
        description
        customer {
          id
          name
          code
        }
      }
    }
  }
`;
