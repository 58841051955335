import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react';
import useLocalStorage from '@msales/shared/hooks/useLocalStorage';
import { useAuthContext } from '.';
import { isAppLockedQuery } from '@msales/shared/graphql/queries/task/isAppLocked';
import { useLazyQuery } from '@apollo/react-hooks';

export const Context = createContext({});

export const Provider = (props) => {
  // Initial values are obtained from the props
  const { children } = props;

  // Use State to keep the values
  const [searchValue, setSearchValue] = useState(null);
  const [customer, setCustomer] = useLocalStorage('customer', null);
  const [dailyRoute, setDailyRoute] = useLocalStorage('dailyRoute', true);
  const [startTime, setStartTime] = useLocalStorage('startTime', null);
  const [resultCount, setResultCount] = useState(0);
  const listRef = useRef(null);

  const isFiltered = searchValue && searchValue.length > 0;

  const clearFilters = () => setSearchValue(null);

  const { authUser } = useAuthContext();

  const [isLocked, { loading, data: lockedData }] =
    useLazyQuery(isAppLockedQuery);

  useEffect(() => {
    if (authUser) isLocked();
  }, [authUser]);

  // Make the context object:
  const customerContext = {
    searchValue,
    setSearchValue,
    customer,
    setCustomer,
    listRef,
    resultCount,
    setResultCount,
    dailyRoute,
    setDailyRoute,
    isFiltered,
    clearFilters,
    startTime,
    setStartTime,
    lockedData,
    loadingLockedData: loading,
  };

  // pass the value in provider and return
  return (
    <Context.Provider value={customerContext}>{children}</Context.Provider>
  );
};

export const { Consumer } = Context;

export const useCustomerContext = () => useContext(Context);
